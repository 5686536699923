import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import {
  usersData,
  investmentData,
  csvDownloadData,
  handleValidDate,
  distributorUsers,
  getTimestamp,
  getActiveInvestmentLot,
  getInvestmentLotByProject,
  userInvestmentEmailTemplate,
} from "constants/common"
import { AccessId } from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import { humanize } from "constants/common"
import {
  StatusObjForUserInvestment,
  StatusForUserInvestment,
} from "constants/ConstantFields"
import { sortingByAlphabet } from "constants/sort"
import ReactSelect from "constants/ReactSelect"

const userInvestment = () => {
  const [orders, setData] = useState([])
  const [investmentList, setInvestmentList] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [investments, setInvestments] = useState([])
  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [selectedUser, setSelectedUser] = useState({})
  const [investmentFilter, setInvestmentFilter] = useState({})
  const [selectInvStage, setSelectInvStage] = useState({})
  const [distributor, setDistributor] = useState([])
  const [selectedDistributor, setSelectedDistributor] = useState({})
  const [stopEmail, setStopEmail] = useState(true)
  const [manageDistributorModal, setManageDistributorModal] = useState(false)
  const [investmentLots, setInvestmentLots] = useState([])
  const [selectedInvestmentLotId, setSelectedInvestmentLotId] = useState()

  const [selectedProject, setSelectedProject] = useState({})
  const [lotSize, setLotSize] = useState()
  const [commitmentAmount, setCommitmentAmount] = useState()
  const [tcsAmount, setTcsAmount] = useState()
  const [tcsModal, setTcsModal] = useState(false)
  const [breakupData, setBreakupData] = useState()
  const [userFilter, setUserFilter] = useState("")
  const [searchValue, setSearchValue] = useState("")
  const [total, setTotal] = useState(0)
  const [investmentDate, setInvestmentDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [isUserInvExists, setIsUserInvExists] = useState(false)
  const [premium, setPremium] = useState()
  const [adjustment, setAdjustment] = useState()

  const [finalSelectedRows, setFinalSelectedRows] = useState([])
  const [isSendEmail, setIsSendEmail] = useState(false)
  const [trancheList, setTrancheList] = useState([])
  const [selectedTranche, setSelectedTranche] = useState(null)
  const [distributorList, setDistributorList] = useState([])
  const [selectedDistrinutor, setSelectedDistrinutor] = useState(null)

  const [rmList, setRmList] = useState([])
  const [selectedRm, setSelectedRm] = useState(null)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [downloadErrorModal, setDownloadErrorModal] = useState(false)

  const maxDate = moment().add(5, "days").format("YYYY-MM-DD")

  const masterData = async () => {
    const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
      investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""
    }${selectInvStage?.id ? `&inv_stage=${selectInvStage.id}` : ""}${
      investmentFilter?.id && selectedTranche?.id
        ? `&investmentLotId=${selectedTranche?.id}`
        : ""
    }${selectedDistrinutor ? `&distributorId=${selectedDistrinutor.id}` : ""}${
      selectedRm ? `&relManagerId=${selectedRm.id}` : ""
    }${startDate ? `&start_date=${startDate}` : ""}${
      endDate ? `&end_date=${endDate}` : ""
    }`

    const userInvestmentGetUrl = `admin-user-investment-listing?$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`

    try {
      setLoading(true)

      const userInvResponse = await axiosInstance.get(userInvestmentGetUrl)

      if (userInvResponse) {
        setTotal(userInvResponse.data.total)
        setData(userInvResponse.data?.data || userInvResponse.data)
        let pages = Math.ceil(
          (userInvResponse.data?.total || userInvResponse.total) /
            (userInvResponse.data?.limit || userInvResponse.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const getFilters = async () => {
    try {
      const investmentGetUrl = `investment?$sort[created_at]=-1`
      const invResponse = await axiosInstance.get(investmentGetUrl)
      if (invResponse) {
        setInvestmentList(invResponse?.data?.data || invResponse?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }

  useEffect(() => {
    getFilters()
  }, [])

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "projectType":
        setInvestmentFilter(e)
        break
      case "invLots":
        setSelectedTranche(e)
        break
      case "invStage":
        setSelectInvStage(e)
        break
      case "distributor":
        setSelectedDistrinutor(e)
        break
      case "rm":
        setSelectedRm(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const handleUserSearch = async e => {
    setSearchValue(e)
    if (e.length >= 3) {
      setLoading(true)
      if (e) {
        const usersRes = await axiosInstance.get(
          `/users?$sort[created_at]=-1&$or[0][permissions]=USER&$or[1][permissions]=OPS_USER
            &name=${e}&$limit=50&$skip=0&is_registered=true`
        )
        const users = usersRes?.data?.data || usersRes?.data
        if (users) {
          setUsers(users)
        } else {
          toast.error("something went wrong")
        }
      } else {
        setUsers([])
      }
      setLoading(false)
    }
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  useEffect(() => {
    masterData()
  }, [
    limit,
    skip,
    selectInvStage,
    investmentFilter,
    selectedTranche,
    selectedDistrinutor,
    selectedRm,
    startDate,
    endDate,
  ])

  useEffect(async () => {
    if (investmentFilter?.id) {
      const invLots = await getInvestmentLotByProject(investmentFilter?.id)
      setTrancheList(invLots)
    } else {
      setTrancheList([])
    }
    setSelectedTranche(null)
  }, [investmentFilter])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    clickToExpand: true,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setFinalSelectedRows([...finalSelectedRows, row])
      } else {
        setFinalSelectedRows(finalSelectedRows.filter(r => r.id !== row.id))
      }
    },
    onSelectAll: (isSelect, rows) => {
      setFinalSelectedRows(isSelect ? rows : [])
    },
    selected: finalSelectedRows.map(row => row.id),
  }

  const accessRestriction = DealManagementAccess(AccessId?.TRANSACTION)

  const UserInvestmentColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            {(accessRestriction >= 3 || accessRestriction === "SuperAdmin") &&
            order.inv_stage != "Completed" ? (
              <Link
                to="#"
                className="text-success"
                onClick={() => handleOrderClick(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
          </div>
        </>
      ),
    },
    {
      dataField: "created_at",
      text: "Investment Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },
    {
      dataField: "project_name",
      text: "Project Name",
      sort: true,
    },
    {
      dataField: "investment_lot_name",
      text: "Investment Lot",
      sort: true,
    },
    {
      dataField: "user_name",
      text: "User Name",
      sort: true,
    },

    {
      dataField: "email",
      text: "User Email",
      sort: true,
    },
    {
      dataField: "phone",
      text: "User Phone",
      sort: true,
    },
    {
      dataField: "name_on_pan",
      text: "Name on Pan",
      sort: true,
    },
    {
      dataField: "pan_number",
      text: "PAN Number",
      sort: true,
    },
    {
      dataField: "demat_id",
      text: "Demat Id",
      sort: true,
    },
    {
      dataField: "dp_name",
      text: "DP Name",
      sort: true,
      formatter: (cellContent, row) =>
        Array.isArray(row.depository_master)
          ? row.depository_master.length
            ? row.depository_master[0].dp_name
            : ""
          : "",
    },
    {
      dataField: "inv_stage",
      text: "Investment Stage",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        const status = StatusObjForUserInvestment.find(
          obj => obj.id == cellContent
        )
        return (
          <span
            className={`badge badge-pill font-size-12 badge-soft-${
              status ? status?.colorClassName : "secondary"
            }`}
          >
            {status?.statusText || cellContent}
          </span>
        )
      },
    },
    {
      dataField: "lot_size",
      text: "Bonds",
      sort: true,
    },
    {
      dataField: "pendings.lot_price",
      text: "Current Bond Price",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleAmount(row.pendings.lot_price),
    },
    {
      dataField: "amount",
      text: "Initial Commitment",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleAmount(Math.floor(row.amount)),
    },
    {
      dataField: "pendings.current_tranche_all",
      text: "Called Commitment",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        handleAmount(row.pendings.current_tranche_all),
    },
    {
      dataField: "pendings.amountPaid",
      text: "Amount Paid",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleAmount(row.pendings.amountPaid),
    },

    {
      dataField: "pendings.payable_amount",
      text: "Payable Amount",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        handleAmount(row.pendings.payable_amount),
    },
    {
      dataField: "tcs_amount",
      text: "TCS Amount",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleAmount(row.tcs_amount),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },

    {
      dataField: "face_value",
      text: "Face Value",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div>{handleAmount(row.face_value)}</div>
      ),
    },
    {
      dataField: "called_face_value",
      text: "Called Face Value",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div>{handleAmount(row.called_face_value)}</div>
      ),
    },
    {
      dataField: "redeemed_face_value",
      text: "Redeemed Face Value",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div>{handleAmount(row.redeemed_face_value)}</div>
      ),
    },
    {
      dataField: "accrued_interest",
      text: "Accrued Interest",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div>{handleAmount(row.accrued_interest)}</div>
      ),
    },
    {
      dataField: "premium",
      text: "Premium",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <div>{handleAmount(row.premium)}</div>,
    },
    {
      dataField: "adjustment",
      text: "Adjustment",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div>{handleAmount(row.adjustment)}</div>
      ),
    },
    {
      dataField: "nil_tds_applicable",
      text: "Nil TDS Applicable",
      sort: true,
    },
    {
      dataField: "currency",
      text: "Currency",
      sort: true,
    },
    {
      dataField: "pendings.token_amount_applicable",
      text: "Token Amount Applicable",
      sort: true,
    },

    {
      dataField: "pendings.token_amount",
      text: "Token Amount",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleAmount(row.pendings.token_amount),
    },

    {
      dataField: "is_token_amount_received",
      text: "Token Amount Received",
      sort: true,
    },
    {
      dataField: "is_balance_amount_received",
      text: "Balance Amount Received",
      sort: true,
    },
    {
      dataField: "distributorName",
      text: "Distributor Name",
      sort: true,
    },
    {
      dataField: "distributor_legal_entity_name",
      text: "Distributor Legal Entity Name",
      sort: true,
    },
    {
      dataField: "distributorEmail",
      text: "Distributor Email",
      sort: true,
    },
    {
      dataField: "rm_user_name",
      text: "RM Name",
      sort: true,
    },
    {
      dataField: "rm_email",
      text: "RM Email",
      sort: true,
    },
    {
      dataField: "rm_phone",
      text: "RM Phone",
      sort: true,
    },
    {
      dataField: "id",
      text: "User Investment Id",
      sort: true,
    },
    {
      dataField: "userId",
      text: "User Id",
      sort: true,
    },
    {
      dataField: "refByUser.user_name",
      text: "Referred By",
      sort: true,
    },
    {
      dataField: "refByUser.phone",
      text: "Referee's Phone",
      sort: true,
    },
    {
      dataField: "refByUser.email",
      text: "Referee's Email",
      sort: true,
    },
    {
      dataField: "refByUser.self_ref_code",
      text: "Ref Code",
      sort: true,
    },

    {
      dataField: "updated_at",
      text: "Updated On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const toggleDownloadErrorModal = () => {
    setDownloadErrorModal(!downloadErrorModal)
  }

  const toggleManageDistributor = () => {
    setManageDistributorModal(!manageDistributorModal)
  }

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    return dollarIndianLocale.format(amount)
  }
  const downloadExcel = async (id, lots, date) => {
    if (
      total > 500
      // && !userFilter.length
      // && !investmentFilter?.id
      // && !selectInvStage?.id
    ) {
      setDownloadErrorModal(true)
      return
    }
    try {
      setLoading(true)
      const filterUrl = `${
        userFilter.length >= 3 ? `&name=${userFilter}` : ``
      }${investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""}${
        selectInvStage?.id ? `&inv_stage=${selectInvStage.id}` : ""
      }${
        investmentFilter?.id && selectedTranche?.id
          ? `&investmentLotId=${selectedTranche?.id}`
          : ""
      }${
        selectedDistrinutor ? `&distributorId=${selectedDistrinutor.id}` : ""
      }${selectedRm ? `&relManagerId=${selectedRm.id}` : ""}${
        startDate ? `&start_date=${startDate}` : ""
      }${endDate ? `&end_date=${endDate}` : ""}`

      const downloadUrl = `/download-excel?type=user-investment&$sort[created_at]=-1${filterUrl}`

      const response = await axiosInstance.get(downloadUrl, {
        responseType: "blob",
      })

      console.log(response.headers)
      const contentDispositionHeader = response.headers["content-disposition"]
      console.log("headf", contentDispositionHeader)
      let match
      if (contentDispositionHeader) {
        match = contentDispositionHeader.split("filename=")
      }

      const filename = match ? match[1] : "download.xlsx"
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      const downloadLink = document.createElement("a")
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = filename
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const getFiltersDataList = async () => {
    try {
      setLoading(true)

      const opsUsersRes = await axiosInstance.get(
        `/users?$sort[created_at]=-1&permissions=OPS_USER`
      )
      if (opsUsersRes) {
        const opsUsers = opsUsersRes.data.data
        setDistributorList(
          opsUsers?.filter(user => {
            if (user?.role?.department === "Distributor") {
              user.user_name = user.distributor_kyc?.name
              return true
            }
          })
        )
        setRmList(opsUsers?.filter(ele => ele.roleId == 1))
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getFiltersDataList()
  }, [])

  const handleOrderClick = async arg => {
    setIsEdit(true)
    const order = arg
    setLoading(true)
    setSelectedInvestmentLotId(order.investmentLotId)
    const selectedUser = await axiosInstance.get(`/users/${order.userId}`)
    setLoading(false)

    setSelectedUser(selectedUser.data)
    const currentInv = {
      id: order.investmentId,
      project_name: order.project_name,
    }
    setSelectedProject(currentInv)

    setSelectedDistributor({})
    setStopEmail(true)
    setLotSize(order.lot_size || order.min_lot)
    setCommitmentAmount(Math.floor(order.amount))
    setInvestmentDate(moment(new Date(order.created_at)).format("YYYY-MM-DD"))
    setBreakupData({
      face_value: order?.face_value || 0,
      called_face_value: order?.called_face_value || 0,
      redeemed_face_value: order?.redeemed_face_value || 0,
      accrued_interest: order?.accrued_interest || 0,
      annual_return: order?.annual_return || 0,
    })
    setPremium(order?.premium || 0)
    setAdjustment(order?.adjustment || 0)
    setTcsAmount(Math.ceil(order.tcs_amount))
    setOrderList({
      id: order.id,
      amount: order.amount,
      currency: order.currency,
      email: order.email,
      inv_stage: order.inv_stage,
      project_name: order.investmentId,
      is_balance_amount_received: order.is_balance_amount_received,
      is_token_amount_received: order.is_token_amount_received,
      lot_size: order.lot_size,
      phone: order.phone,
      userId: order.userId,
      name: order.user_name,
      name_on_pan: order.name_on_pan,
      pan_number: order.pan_number,
      distributorId: order.distributorId,
      distributorName: order.distributorName,
      distributorEmail: order.distributorEmail,
      distributorPhone: order.distributorPhone,
    })
    setIsSendEmail(false)
    toggle()
  }

  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (isEdit) {
      const updateOrder = {
        userId: selectedUser.id || orderList?.userId,
        investmentId: values.project_name,
        lot_size: lotSize,
        inv_stage: values.inv_stage,
        amount: parseFloat(values.amount) || 0,
        tcs_amount: parseFloat(tcsAmount) || 0,
        face_value: parseFloat(breakupData?.face_value),
        called_face_value: parseFloat(breakupData?.called_face_value),
        redeemed_face_value: parseFloat(breakupData?.redeemed_face_value),
        accrued_interest: parseFloat(breakupData?.accrued_interest),
        premium: parseFloat(premium),
        adjustment: parseFloat(adjustment),
        annual_return: parseFloat(breakupData?.annual_return),
        created_at: getTimestamp(investmentDate),
        investmentLotId: selectedInvestmentLotId,
      }
      // update order
      const dontSendEmail = stopEmail || values.inv_stage == "Commitment"
      try {
        const userInvResponse = await axiosInstance.patch(
          `user-investment/${orderList.id}${
            dontSendEmail ? "?send_email=false" : ""
          }`,
          updateOrder
        )
        if (
          userInvResponse &&
          (userInvResponse.data.inv_stage === "Commitment" ||
            userInvResponse.data.inv_stage === "Initiate_Balance_Payment" ||
            userInvResponse.data.inv_stage === "Awaiting_Manual_Transfer" ||
            userInvResponse.data.inv_stage === "Awaiting_Token_Manual_Transfer")
        ) {
          await createTransaction(userInvResponse.data)
        }
        masterData()
        toast.success("Successfully Edited")
        toggle()
      } catch (error) {
        const msg = error?.response?.data?.errors[0]?.message
        toast.error(humanize(msg))
      }
    } else if (isSendEmail) {
      try {
        const mailObj = {
          template_id: parseInt(values.templateId),
          topup: false,
          data: finalSelectedRows.map(item => ({
            userId: item.userId,
            userInvestmentId: item.id,
          })),
        }
        const response = await axiosInstance.post(`send-email`, mailObj)
        if (response) {
          setFinalSelectedRows([])
          toast.success("Email Sent Successfully.")
        }
      } catch (error) {
        console.log({ error })
        toast.error(error)
      }
      toggle()
    } else if (manageDistributorModal) {
      if (!selectedDistributor?.id) {
        toast.error("please select a distributor")
        setLoading(false)
        return false
      }
      const newDistributor = {
        distributorId:
          selectedDistributor.id === "null" ? null : selectedDistributor.id,
      }
      for (let i = 0; i < finalSelectedRows.length; i++) {
        try {
          const userInvResponse = await axiosInstance.patch(
            `user-investment/${finalSelectedRows[i].id}`,
            newDistributor
          )
          if (userInvResponse) {
            toast.success("Successfully Edited")
            setFinalSelectedRows([])
          }
        } catch (error) {
          const msg = error?.response?.data?.errors[0]?.message
          toast.error(humanize(msg))
        }
      }
      masterData()
      toggleManageDistributor()
    } else {
      const newOrder = {
        userId: selectedUser.id,
        investmentId: values.project_name,
        lot_size: lotSize,
        inv_stage: values.inv_stage,
        amount: parseFloat(values.amount) || 0,
        distributorId: selectedUser.createdById,
        tcs_amount: parseFloat(tcsAmount) || 0,
        face_value: parseFloat(breakupData?.face_value),
        called_face_value: parseFloat(breakupData?.called_face_value),
        redeemed_face_value: parseFloat(breakupData?.redeemed_face_value),
        accrued_interest: parseFloat(breakupData?.accrued_interest),
        premium: parseFloat(premium),
        adjustment: parseFloat(adjustment),
        annual_return: parseFloat(breakupData?.annual_return),
        created_at: getTimestamp(investmentDate),
        investmentLotId: selectedInvestmentLotId,
      }
      // save new order
      const dontSendEmail = stopEmail || values.inv_stage == "Commitment"

      try {
        const userInvResponse = await axiosInstance.post(
          `user-investment${dontSendEmail ? "?send_email=false" : ""}`,
          newOrder
        )
        if (
          userInvResponse &&
          (userInvResponse.data.inv_stage === "Commitment" ||
            userInvResponse.data.inv_stage === "Initiate_Balance_Payment" ||
            userInvResponse.data.inv_stage === "Awaiting_Manual_Transfer" ||
            userInvResponse.data.inv_stage === "Awaiting_Token_Manual_Transfer")
        ) {
          await createTransaction(userInvResponse.data)
        }
        masterData()
        toast.success("Successfully Added")
        toggle()
      } catch (error) {
        const msg = error?.response?.data?.errors[0]?.message
        toast.error(humanize(msg))
      }
    }
    setLoading(false)
  }
  const createTransaction = async userInvRes => {
    const transactionData = {
      transaction_type: "Direct",
      payment_type: "Bank_Transfer",
      userInvestmentId: userInvRes.id,
      transaction_amount: userInvRes.pendings.payable_amount * 100,
      payment_status: "initiated",
      userId: userInvRes.userId,
      created_at: userInvRes.created_at,
    }
    const res = await axiosInstance.post(`transaction`, transactionData)
    if (userInvRes.tcs_amount !== 0) {
      const tcsData = {
        userInvestmentId: userInvRes.id,
        userId: userInvRes.userId,
        transaction_amount: userInvRes.tcs_amount * 100,
        payment_status: "initiated",
        payment_type: "Bank_Transfer",
        created_at: userInvRes.created_at,
      }

      const response = await axiosInstance.post(`tcs-transactions`, tcsData)
    }
  }
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const handleMultipleIds = async str => {
    if (!finalSelectedRows.length) {
      toast.error("Please select atleast a user")
      return false
    }
    if (finalSelectedRows.length > 1) {
      toast.error("Please select only one user")
      return
    }
    setLoading(true)

    switch (str) {
      case "manage_distributor":
        const distributorRes = await distributorUsers()
        if (distributorRes) {
          const distributorFromUser = distributorRes?.data?.data.filter(
            obj => obj.role?.department === "Distributor"
          )
          setDistributor(distributorFromUser)
        } else {
          toast.error("something went wrong")
        }
        toggleManageDistributor()
        break
      case "send_email":
        setIsSendEmail(true)
        setIsEdit(false)
        toggle()
        break
      default:
        break
    }
    setLoading(false)
  }

  const investmentCalc = async (
    lots = lotSize,
    date = investmentDate,
    adjustmentAmount = adjustment
  ) => {
    setLoading(true)
    const getUrl = `investment-calc-new/${
      selectedProject?.id
    }?multr=${lots}&userId=${selectedUser?.id}&date=${date}${
      adjustmentAmount ? `&adjustment=${adjustmentAmount}` : ""
    }`
    try {
      // check user investment already exists for this user in this project
      const existingUserInv = await axiosInstance.get(
        `admin-user-investment-listing?investmentId=${selectedProject?.id}&userId=${selectedUser?.id}`
      )
      if (existingUserInv) {
        const userInv = existingUserInv.data?.data || existingUserInv.data
        if (userInv.length) {
          setIsUserInvExists(true)
          setCommitmentAmount(0)
          setTcsAmount(0)
          setAdjustment(0)
          setBreakupData({
            face_value: 0,
            called_face_value: 0,
            redeemed_face_value: 0,
            accrued_interest: 0,
            annual_return: 0,
          })
          setPremium(0)
          if (userInv[0].inv_stage == "Completed") {
            toast.error(
              "User investment already exists, create top-up user investment"
            )
            setLoading(false)
            return
          }
          if (!isEdit) {
            toast.error("Edit existing user investment for this user")
            setLoading(false)
            return
          }
        }
      }
      setIsUserInvExists(false)
      const tcsAmountRes = await axiosInstance.get(getUrl)
      if (tcsAmountRes) {
        setCommitmentAmount(Math.floor(tcsAmountRes?.data?.base_amount))
        setTcsAmount(Math.ceil(tcsAmountRes?.data?.tcs_amount))
        setBreakupData({
          face_value: tcsAmountRes?.data?.face_value * Number(lots),
          called_face_value:
            tcsAmountRes?.data?.called_face_value * Number(lots),
          redeemed_face_value:
            tcsAmountRes?.data?.redeemed_face_value * Number(lots),
          accrued_interest: tcsAmountRes?.data?.accrued_interest * Number(lots),
          annual_return: tcsAmountRes?.data?.annual_return,
        })
        setPremium(tcsAmountRes?.data?.premium * Number(lots))
        setAdjustment(adjustmentAmount)
      }
    } catch (error) {
      toast.error(error.message)
    }
    setLoading(false)
  }

  const handleProjectName = e => {
    const currentInv = investments.find(val => val.id == e.target.value)
    setSelectedProject(currentInv)
    let event = {
      target: {
        value: currentInv.min_lot,
      },
    }
    handleLotSize(event)
  }
  const handleLotSize = async e => {
    let lotSize = parseInt(e.target.value)
    setLotSize(lotSize)
    if (
      isEdit &&
      selectedProject?.id &&
      selectedUser?.id &&
      lotSize &&
      investmentDate
    ) {
      investmentCalc(lotSize)
    }
  }

  const handleInvestmentDate = e => {
    const date = e.target.value
    setInvestmentDate(date)
    if (isEdit && selectedProject?.id && selectedUser?.id && lotSize && date) {
      investmentCalc(lotSize, date)
    }
  }

  useEffect(async () => {
    if (
      !isEdit &&
      selectedProject?.id &&
      selectedUser?.id &&
      lotSize &&
      investmentDate
    ) {
      investmentCalc()
    }
  }, [selectedProject, selectedUser, lotSize, investmentDate])

  useEffect(async () => {
    if (selectedProject?.id) {
      setLoading(true)
      const invLots = await getInvestmentLotByProject(selectedProject.id)
      setSelectedInvestmentLotId(invLots.find(val => val.status)?.id)
      setInvestmentLots(invLots)
      setLoading(false)
    }
  }, [selectedProject])
  useEffect(async () => {
    if (modal) {
      setUsers([])
      const investments = await investmentData(isEdit ? "" : "Approved")
      setInvestments(investments?.data?.data)
    } else {
      setSelectedUser(null)
      setSelectedDistributor(null)
      setOrderList("")
      setStopEmail(true)
      setSelectedProject({})
      setIsUserInvExists(false)
      setLotSize()
      setInvestmentDate(moment(new Date()).format("YYYY-MM-DD"))
      setCommitmentAmount()
      setTcsAmount()
      setBreakupData()
      setSelectedInvestmentLotId()
      setInvestmentLots([])
      setIsEdit(false)
      setAdjustment(0)
      setIsSendEmail(false)
    }
  }, [modal])

  // useEffect(() => {
  //   if (breakupData) {
  //     const { face_value, redeemed_face_value, accrued_interest } = breakupData
  //     if (adjustment) {
  //     }
  //     setCommitmentAmount(
  //       parseFloat(face_value) -
  //         parseFloat(redeemed_face_value) +
  //         parseFloat(accrued_interest) +
  //         parseFloat(premium ? premium : 0) +
  //         parseFloat(adjustment ? adjustment : 0)
  //     )
  //   }
  // }, [premium, adjustment])

  const onAdjustmentChange = async e => {
    const value = e.target.value
    setAdjustment(value)
    await investmentCalc(lotSize, investmentDate, value)
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Users Investment" />
          <Row>
            <Col xs="12">
              <Row className="mb-2 row justify-content-between">
                <Col md={2}>
                  <select
                    className="form-select w-75"
                    value={limit}
                    onChange={e => handleFilterChange(e, "limit")}
                  >
                    {[10, 30, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                <div className="col-auto">
                  {(accessRestriction >= 2 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded  mb-2 me-2"
                      onClick={toggle}
                    >
                      <i className="mdi mdi-plus me-1" /> Add
                    </Button>
                  )}
                  {(accessRestriction >= 3 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded mb-2 me-2"
                      style={{ marginRight: "3px" }}
                      onClick={() => handleMultipleIds("send_email")}
                      disabled={finalSelectedRows.length != 1}
                    >
                      <i className="mdi mdi-pencil me-1" /> Send Email
                    </Button>
                  )}
                  {/* {(accessRestriction >= 3 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      style={{ marginRight: "3px" }}
                      onClick={() => handleMultipleIds("manage_distributor")}
                      disabled={finalSelectedRows?.length != 1}
                    >
                      <i className="mdi mdi-pencil me-1" /> Manage Distributor
                    </Button>
                  )} */}
                  {(accessRestriction == "4" ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      onClick={() => {
                        downloadExcel("Users Investment")
                      }}
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                      Download Excel
                    </Button>
                  )}
                </div>
              </Row>
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={UserInvestmentColumns(toggle)}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row">
                          <Col md={3}>
                            {/* <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block"> */}
                            <div className="position-relative">
                              <label>Search User</label>
                              <input
                                onChange={e => handleFilterChange(e, "user")}
                                id="search-bar-0"
                                type="text"
                                className="form-control rounded custom-input-height"
                                placeholder={`Name, Email, Phone, Pan Name or Pan`}
                                value={userFilter || ""}
                              />
                              {/* <i className="bx bx-search-alt"></i> */}
                            </div>
                            {/* </div> */}
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Project Name</label>
                              <ReactSelect
                                users={investmentList}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "projectType")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["project_name"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Investment Lot</label>
                              <ReactSelect
                                users={[...trancheList]}
                                selectedOption={selectedTranche}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "invLots")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["investment_lot_name"]}
                                isClearable={true}
                                isDisabled={!investmentFilter?.id}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Investment Stage</label>
                              <ReactSelect
                                users={StatusObjForUserInvestment}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "invStage")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["statusText"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Distributor</label>
                              <ReactSelect
                                users={[
                                  {
                                    id: "null",
                                    user_name: "None",
                                    email: "",
                                    phone: "",
                                  },
                                  ...distributorList,
                                ]}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "distributor")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={[
                                  "user_name",
                                  "email",
                                  "phone",
                                ]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Relationship Manager</label>
                              <ReactSelect
                                users={rmList}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "rm")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={[
                                  "user_name",
                                  "email",
                                  "phone",
                                ]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-4">
                              <Label>Investment Start Date</Label>
                              <Input
                                name="date"
                                type="date"
                                value={startDate}
                                onChange={e => {
                                  setStartDate(e.target.value)
                                }}
                                className="custom-input-height"
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-4">
                              <Label>Investment End Date</Label>
                              <Input
                                name="date"
                                type="date"
                                value={endDate}
                                onChange={e => setEndDate(e.target.value)}
                                className="custom-input-height"
                              />
                            </div>
                          </Col>
                          {/* <div className="col">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </div> */}
                        </Row>

                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              columns={UserInvestmentColumns(toggle)}
                              data={orders}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal isOpen={modal} toggle={toggle}>
                              <ModalHeader toggle={toggle} tag="h4">
                                {!!isEdit
                                  ? "Edit User Investment"
                                  : isSendEmail
                                  ? "Send Email"
                                  : "Add User Investment"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row form>
                                    {isSendEmail ? (
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <AvField
                                            name="templateId"
                                            label="Select the type of email"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid email template"
                                            value={orderList.templateId || ""}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            style={{ width: "100%" }}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            {userInvestmentEmailTemplate.map(
                                              (item, index) => (
                                                <option
                                                  key={item.templateName}
                                                  value={item.id}
                                                >
                                                  {item.templateName}
                                                </option>
                                              )
                                            )}
                                          </AvField>
                                        </div>
                                      </Col>
                                    ) : (
                                      <Col className="col-12">
                                        {!isEdit ? (
                                          <div className="mb-3">
                                            <label>User</label>
                                            {/* <ReactSelect
                                            isDisabled={isEdit}
                                            users={users}
                                            setSelectedOption={
                                              setSelectedOption
                                            }
                                          /> */}
                                            <ReactSelect
                                              users={users}
                                              searchValue={searchValue}
                                              setSearchValue={handleUserSearch}
                                              selectedOption={selectedUser}
                                              setSelectedOption={
                                                setSelectedUser
                                              }
                                              multiOptionLabel={false}
                                              optionLabelKeys={[
                                                "user_pan?.name",
                                                "email",
                                                "phone",
                                                "user_pan?.pan_number",
                                              ]}
                                            />
                                          </div>
                                        ) : (
                                          <div className="mb-3">
                                            <label>User</label>
                                            <ReactSelect
                                              isDisabled={isEdit}
                                              users={users}
                                              selectedOption={{
                                                user_pan: {
                                                  name: orderList.name_on_pan,
                                                  pan_number:
                                                    orderList.pan_number,
                                                },
                                                email: orderList.email,
                                                phone: orderList.phone,
                                              }}
                                              setSelectedOption={
                                                setSelectedUser
                                              }
                                              multiOptionLabel={false}
                                              optionLabelKeys={[
                                                "user_pan?.name",
                                                "email",
                                                "phone",
                                                "user_pan?.pan_number",
                                              ]}
                                            />
                                          </div>
                                        )}
                                        {/* {!isEdit ? (
                                        <div className="mb-3">
                                          <label>Distributor</label>
                                          <ReactSelect
                                            users={distributor}
                                            setSelectedOption={
                                              setSelectedDistributor
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <div className="mb-3">
                                          <label>Distributor</label>
                                          <ReactSelect
                                            users={distributor}
                                            selectedOption={{
                                              user_name:
                                                orderList.distributorName,
                                              email: orderList.distributorEmail,
                                              phone: orderList.distributorPhone,
                                            }}
                                            setSelectedOption={
                                              setSelectedDistributor
                                            }
                                          />
                                        </div>
                                      )} */}
                                        <div className="row">
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="project_name"
                                              label="Project Name"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid value"
                                              sort="true"
                                              disabled={isEdit}
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={selectedProject.id}
                                              onChange={handleProjectName}
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              {sortingByAlphabet(
                                                investments?.map(
                                                  (item, index) => (
                                                    <option
                                                      key={item?.project_name}
                                                      value={item?.id}
                                                    >
                                                      {item?.project_name}
                                                    </option>
                                                  )
                                                )
                                              )}
                                            </AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="created_at"
                                              label="Investment Date (IST)"
                                              type="date"
                                              errorMessage="Invalid Value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={investmentDate}
                                              max={maxDate}
                                              disabled={isUserInvExists}
                                              onChange={handleInvestmentDate}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="investmentLotId"
                                              label="Investment Lot"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid value"
                                              sort="true"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={selectedInvestmentLotId}
                                              onChange={e => {
                                                setSelectedInvestmentLotId(
                                                  e.target.value
                                                )
                                              }}
                                              disabled={!selectedProject?.id}
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              {sortingByAlphabet(
                                                investmentLots?.map(
                                                  (item, index) => {
                                                    if (item.status)
                                                      return (
                                                        <option
                                                          key={item?.id}
                                                          value={item?.id}
                                                        >
                                                          {
                                                            item?.investment_lot_name
                                                          }
                                                        </option>
                                                      )
                                                  }
                                                )
                                              )}
                                            </AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="lot_size"
                                              label="Bonds"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={lotSize || 0}
                                              onChange={handleLotSize}
                                              pattern="^\d+$"
                                              step={1}
                                              disabled={isUserInvExists}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="amount"
                                              label="Initial Commitment"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                commitmentAmount === 0
                                                  ? "0"
                                                  : commitmentAmount
                                              }
                                              onChange={e =>
                                                setCommitmentAmount(
                                                  e.target.value
                                                )
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>

                                          <div className="mb-3 col-md-6">
                                            <Modal
                                              isOpen={tcsModal}
                                              toggle={() => {
                                                setTcsModal(!tcsModal)
                                              }}
                                            >
                                              <ModalHeader
                                                toggle={() => {
                                                  setTcsModal(!tcsModal)
                                                }}
                                                tag="h3"
                                              >
                                                TCS
                                              </ModalHeader>
                                              <ModalBody>
                                                <p className="font-size-16">
                                                  0.1% TCS is applicable on
                                                  gross investments above 50
                                                  lacs in a financial year. The
                                                  same will be deposited against
                                                  investor’s PAN (like TDS) and
                                                  can be claimed in his/her tax
                                                  return.
                                                </p>
                                              </ModalBody>
                                            </Modal>
                                            <Label>
                                              TCS{" "}
                                              <i
                                                className="fas fa-info-circle"
                                                id="TooltipTop"
                                                onClick={() => {
                                                  setTcsModal(true)
                                                }}
                                              ></i>
                                            </Label>
                                            <AvField
                                              name="tcsAmount"
                                              type="number"
                                              errorMessage="Invalid value"
                                              value={tcsAmount}
                                              onChange={e =>
                                                setTcsAmount(e.target.value)
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="face_value"
                                              label="Face Value"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                breakupData?.face_value === 0
                                                  ? "0"
                                                  : breakupData?.face_value
                                              }
                                              onChange={e =>
                                                setBreakupData({
                                                  ...breakupData,
                                                  face_value: e.target.value,
                                                })
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="called_face_value"
                                              label="Called Face Value"
                                              type="text"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                breakupData?.called_face_value ===
                                                0
                                                  ? "0"
                                                  : breakupData?.called_face_value
                                              }
                                              onChange={e =>
                                                setBreakupData({
                                                  ...breakupData,
                                                  called_face_value:
                                                    e.target.value,
                                                })
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="redeemed_face_value"
                                              label="Redeemed Face Value"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                breakupData?.redeemed_face_value ===
                                                0
                                                  ? "0"
                                                  : breakupData?.redeemed_face_value
                                              }
                                              onChange={e =>
                                                setBreakupData({
                                                  ...breakupData,
                                                  redeemed_face_value:
                                                    e.target.value,
                                                })
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="accrued_interest"
                                              label="Accrued Interest"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                breakupData?.accrued_interest ===
                                                0
                                                  ? "0"
                                                  : breakupData?.accrued_interest
                                              }
                                              onChange={e =>
                                                setBreakupData({
                                                  ...breakupData,
                                                  accrued_interest:
                                                    e.target.value,
                                                })
                                              }
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="premium"
                                              label="Premium"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                premium === 0 ? "0" : premium
                                              }
                                              onChange={e =>
                                                setPremium(e.target.value)
                                              }
                                              // disabled={isUserInvExists}
                                              disabled={true}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="adjustment"
                                              label="Adjustment"
                                              type="number"
                                              value={
                                                adjustment == 0
                                                  ? "0"
                                                  : adjustment
                                              }
                                              onChange={onAdjustmentChange}
                                              disabled={isUserInvExists}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="inv_stage"
                                              label="Status"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid value"
                                              sort={true}
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={orderList.inv_stage || ""}
                                              disabled={isUserInvExists}
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              {StatusForUserInvestment?.map(
                                                (item, index) => (
                                                  <option
                                                    key={index}
                                                    value={item}
                                                  >
                                                    {humanize(item)}
                                                  </option>
                                                )
                                              )}
                                            </AvField>
                                          </div>
                                        </div>

                                        <div className="mb-3">
                                          <AvField
                                            name="sendEmail"
                                            label="Do not send email"
                                            type="checkbox"
                                            value={stopEmail}
                                            onChange={e => {
                                              setStopEmail(e.target.checked)
                                            }}
                                          ></AvField>
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                          disabled={isUserInvExists}
                                        >
                                          {isSendEmail ? "Send Email" : "Save"}
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                            <Modal
                              isOpen={downloadErrorModal}
                              toggle={toggleDownloadErrorModal}
                            >
                              <ModalHeader
                                toggle={toggleDownloadErrorModal}
                                tag="h4"
                              >
                                Invalid Request!
                              </ModalHeader>
                              <ModalBody>
                                <Row form>
                                  Download requested for more than 500 entries.
                                  Please select a filter before proceeding.
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="text-end">
                                      <button
                                        type="button"
                                        className="btn btn-success save-user"
                                        onClick={toggleDownloadErrorModal}
                                      >
                                        OK
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </ModalBody>
                            </Modal>
                            <Modal
                              isOpen={manageDistributorModal}
                              toggle={toggleManageDistributor}
                            >
                              <ModalHeader
                                toggle={toggleManageDistributor}
                                tag="h4"
                              >
                                Manage Distributor
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row form>
                                    <Col className="col-12">
                                      <div className="mb-3">
                                        <label>Distributor</label>
                                        <ReactSelect
                                          users={[
                                            {
                                              id: "null",
                                              user_name: "None",
                                              email: "",
                                              phone: "",
                                            },
                                            ...distributor,
                                          ]}
                                          setSelectedOption={
                                            setSelectedDistributor
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                        <Row className="justify-content-md-space-between justify-content-center align-items-center">
                          <Col className="col-12 col-md-auto mb-3">
                            {`Showing ${total ? skip + 1 + " to" : ""} ${
                              limit > total || limit + skip > total
                                ? total
                                : limit + skip
                            } rows of ${total}${
                              finalSelectedRows.length
                                ? ` | Selected rows: ${finalSelectedRows.length}`
                                : ""
                            }
                    `}
                          </Col>
                          <Col>
                            <Row className="justify-content-md-end justify-content-center align-items-center">
                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(limit)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<<"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(skip)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<"}
                                  </Button>
                                </div>
                              </Col>
                              <Col className="col-md-auto d-none d-md-block">
                                Page{" "}
                                <strong>{`${currentPage ? currentPage : 1} of ${
                                  totalPage ? totalPage : 1
                                }`}</strong>
                              </Col>
                              <Col className="col-md-auto">
                                <Input
                                  type="number"
                                  min={1}
                                  style={{ width: 70 }}
                                  max={total == 0 ? 1 : totalPage}
                                  value={currentPage || 1}
                                  defaultValue={1}
                                  onChange={onChangePagination}
                                  disabled={total == 0}
                                />
                              </Col>

                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handleNext(skip)}
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      handleNext((totalPage - 2) * limit)
                                    }
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">>"}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

userInvestment.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
}

export default withRouter(userInvestment)
