import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { csvDownloadData, handleValidDate } from "constants/common"
import { AccessId, dematTransferRequestsStatus } from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import moment from "moment"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Input,
  Label,
} from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import ReactMultiSelect from "constants/ReactMultiSelect"
import ReactSelect from "constants/ReactSelect"
import { Link } from "@material-ui/core"

const DematTransferRequest = () => {
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [limit, setLimit] = useState(10)
  const [skip, setSkip] = useState(0)
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [createRequestModal, setCreateRequestModal] = useState(false)
  const [editRequestMappingPendingModal, setEditRequestMappingPendingModal] =
    useState(false)
  const [editRequestFailedModal, setEditRequestFailedModal] = useState(false)
  const [stfDocuments, setStfDocuments] = useState([])
  const [selectedMultiStfDocument, setSelectedMultiStfDocument] = useState([])
  const [group, setGroup] = useState(false)
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"))
  const [finalSelectedRows, setFinalSelectedRows] = useState([])
  const [selectedRow, setSelectedRow] = useState({})
  const [userFilter, setUserFilter] = useState("")
  const [projectFilter, setProjectFilter] = useState("")
  const [investmentList, setInvestmentList] = useState([])
  const [statusFilter, setStatusFilter] = useState("")
  const [requestDateFilter, setRequestDateFilter] = useState("")
  const [selectAll, setSelectAll] = useState(false)
  const [downloadErrorModal, setDownloadErrorModal] = useState(false)
  const accessRestriction = DealManagementAccess(AccessId?.TRANSACTION)
  const [markAsFailedSelected, setMarkAsFailedSelected] = useState()
  const defaultSorted = [
    {
      dataField: "updated_at",
      order: "desc",
    },
  ]

  const TableColumns = download => {
    const columns = [
      {
        dataField: "",
        isDummyField: true,
        text: "Action",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => (
          <>
            <div className="d-flex gap-3">
              {accessRestriction === "SuperAdmin" &&
              order.status === "ACTION_PENDING" ? (
                <Link to="#" className="text-success text-center">
                  <Button
                    outline
                    type="button"
                    color="danger"
                    className="btn-md btn-rounded"
                    onClick={() => {
                      setEditRequestMappingPendingModal(
                        !editRequestMappingPendingModal
                      )
                      setMarkAsFailedSelected(order)
                    }}
                  >
                    Mark as Failed
                  </Button>
                </Link>
              ) : (
                "N/A"
              )}
            </div>
          </>
        ),
      },
      {
        dataField: "investment.project_name",
        text: "Project Name",
        sort: true,
      },
      { dataField: "user.user_name", text: "Investor Name", sort: true },
      {
        dataField: "user.user_demat.demat_id",
        text: "Investor Demat Id",
        sort: true,
      },

      {
        dataField: "quantity",
        text: "Quantity",
        sort: true,
      },
      {
        dataField: "consideration_amount",
        text: "Consideration Amount",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          return <span>{handleAmount(cellContent)}</span>
        },
      },
      {
        dataField: "",
        text: "Investment Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          return (
            <span>
              {row?.stf_documents?.length > 0 &&
                row.stf_documents[0]?.user_investment_topup?.created_at &&
                handleValidDate(
                  row.stf_documents[0]?.user_investment_topup?.created_at
                )}
            </span>
          )
        },
      },
      {
        dataField: "request_date",
        text: "Transfer Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          return <span>{handleValidDate(cellContent)}</span>
        },
      },
      {
        dataField: "status",
        text: "Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          const status = dematTransferRequestsStatus.find(
            obj => obj.id == cellContent
          )
          return (
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status ? status?.colorClassName : "secondary"
              }`}
            >
              {status?.statusText || cellContent}
            </span>
          )
        },
      },
      { dataField: "user.email", text: "Investor Email", sort: true },
      { dataField: "user.phone", text: "Investor Phone", sort: true },
    ]
    return columns
  }

  const toggleCreateRequestModal = () => {
    createRequestModal === false && getStfDocuments()
    setGroup(false)
    setCreateRequestModal(!createRequestModal)
  }

  const toggleEditRequestFailedModal = () => {
    setDate(moment().format("YYYY-MM-DD"))
    setEditRequestFailedModal(!editRequestFailedModal)
  }

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    clickToExpand: true,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedRow(row)
        setFinalSelectedRows([...finalSelectedRows, row])
      } else {
        setFinalSelectedRows(finalSelectedRows.filter(r => r.id !== row.id))
      }
    },
    onSelectAll: (isSelect, rows) => {
      setFinalSelectedRows(isSelect ? rows : [])
    },
    selected: finalSelectedRows.map(row => row.id),
  }
  const filterUrl = `${projectFilter ? `&investmentId=${projectFilter}` : ``}${
    statusFilter ? `&status=${statusFilter}` : ``
  }${requestDateFilter ? `&request_date=${requestDateFilter}` : ``}`

  const masterData = async () => {
    setLoading(true)
    const investmentGetUrl = `investment?$sort[created_at]=-1`

    const getUrl = `/demat-transfer-requests?$sort[updated_at]=-1&$limit=${limit}&$skip=${skip}${filterUrl}`

    try {
      const response = await axiosInstance.get(getUrl)
      const invResponse = await axiosInstance.get(investmentGetUrl)

      if (response) {
        setTotal(response.data.total)
        const data = response?.data?.data || response?.data
        setTableData(data)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
        if (invResponse) {
          setInvestmentList(invResponse.data?.data || invResponse.data)
        }
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    return dollarIndianLocale.format(amount)
  }

  const downloadExcel = async () => {
    if (total > 500) {
      setDownloadErrorModal(true)
      return
    }
    try {
      setLoading(true)

      const downloadUrl = `/download-excel?type=demat-transfer-requests&$sort[created_at]=-1${filterUrl}`
      const response = await axiosInstance.get(downloadUrl, {
        responseType: "blob",
      })

      const contentDispositionHeader = response.headers["content-disposition"]
      console.log("headf", contentDispositionHeader)
      let match
      if (contentDispositionHeader) {
        match = contentDispositionHeader.split("filename=")
      }

      const filename = match ? match[1] : "download.xlsx"
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      const downloadLink = document.createElement("a")
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = filename
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  // SSP Filters
  const handleFilterChange = (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e?.target?.value)
        break
      case "limit":
        setLimit(Number(e?.target?.value))
        break
      case "projectType":
        setProjectFilter(e?.id)
        break
      case "status":
        setStatusFilter(e?.id)
        break
      case "requestDate":
        setRequestDateFilter(e?.target?.value)
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }
  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter, projectFilter, statusFilter, requestDateFilter])

  useEffect(async () => {
    masterData()
  }, [limit, skip])

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }
  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }
  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const getStfDocuments = async () => {
    try {
      setLoading(true)
      setStfDocuments([])
      setSelectedMultiStfDocument([])
      setSelectAll(false)
      const res = await axiosInstance.get(
        `/stf-document?$sort[created_at]=-1&status=SIGNED&dematTransferRequestId=null`
      )
      if (res.data.data.length) {
        setStfDocuments(res.data.data)
      } else {
        toast.warn("No pending STFs!")
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const createDematTransferRequest = async () => {
    try {
      setLoading(true)
      const stfData = selectAll
        ? formattedStfDocuments
        : selectedMultiStfDocument
      const response = await axiosInstance.post("/demat-transfer-requests", {
        data: stfData?.map(el => {
          el.stfId = el.id
          el.request_date = date
          delete el.id
          return el
        }),
        type: group ? "group" : "",
      })
      toast.success(
        `${
          response?.data?.data?.length
            ? response?.data?.data?.length
            : response?.data?.length
        } Demat Transfer Requests Added`
      )
      masterData()
      setSelectedMultiStfDocument([])
      setCreateRequestModal(false)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const updateDTRequestFailed = async () => {
    try {
      setLoading(true)
      await axiosInstance.patch(`/demat-transfer-requests/${selectedRow.id}`, {
        status: "RE_INITIATED",
        request_date: date,
      })
      toast.success(`Demat Transfer Status Updated`)
      masterData()
      setEditRequestFailedModal(!editRequestFailedModal)
      setSelectedRow()
      setFinalSelectedRows([])
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const updateDTRequestMappingPending = async () => {
    try {
      setLoading(true)
      await axiosInstance.patch(
        `/demat-transfer-requests/${markAsFailedSelected.id}`,
        {
          status: "TRANSFER_FAILED",
        }
      )
      toast.success(`Demat Transfer Status Updated`)
      masterData()
      setEditRequestMappingPendingModal(!editRequestMappingPendingModal)
      setMarkAsFailedSelected()
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const formattedStfDocuments = stfDocuments?.map(item => {
    return {
      investmentId: item.user_investment_topup.investmentId,
      userId: item.user_investment_topup.userId,
      consideration_amount: item.user_investment_topup.amount,
      quantity: item?.user_investment_topup?.lot_size,
      id: item.id,
      label: `${item?.user_investment_topup?.investment?.project_name}, User: ${
        item?.user_investment_topup?.user?.user_name
      },
              Quantity: ${item?.user_investment_topup?.lot_size},
              Amount: ${handleAmount(item?.user_investment_topup?.amount)},
              Date: ${moment(item?.user_investment_topup?.created_at).format(
                "DD MMM Y"
              )}`,
    }
  })

  const showUpdateStatusFilter = () => {
    if (finalSelectedRows[0]?.status === "ACTION_PENDING") {
      return [
        dematTransferRequestsStatus.find(item => item.id === "TRANSFER_FAILED"),
      ]
    } else if (finalSelectedRows[0]?.status === "TRANSFER_FAILED") {
      return [
        dematTransferRequestsStatus.find(
          item => item.id === "TRANSFER_INITIATED"
        ),
      ]
    } else {
      return dematTransferRequestsStatus
    }
  }

  const toggleDownloadErrorModal = () => {
    setDownloadErrorModal(!downloadErrorModal)
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Demat Transfer Requests" />
          <Row>
            <Col xs="12">
              <Row className="mb-2 row justify-content-between">
                <Col md={2}>
                  <select
                    className="form-select w-75"
                    value={limit}
                    onChange={e => handleFilterChange(e, "limit")}
                  >
                    {[10, 30, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                <div className="col-auto">
                  {(accessRestriction >= 2 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded  mb-2 me-2"
                      onClick={toggleCreateRequestModal}
                    >
                      <i className="mdi mdi-plus me-1" />
                      Create Request
                    </Button>
                  )}
                  {(accessRestriction >= 4 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded  mb-2 me-2"
                      onClick={toggleEditRequestFailedModal}
                      disabled={
                        finalSelectedRows?.length != 1 ||
                        finalSelectedRows[0].status !== "TRANSFER_FAILED"
                      }
                    >
                      <i className="mdi mdi-pencil me-1" />
                      Re-Initiate Request
                    </Button>
                  )}
                  {(accessRestriction == 4 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded mb-2 me-2"
                      onClick={() => downloadExcel("Demat Transfer Requests")}
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                      Download
                    </Button>
                  )}
                </div>
              </Row>
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={tableData}
                    columns={TableColumns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row justify-content-start">
                          {/* <Col md={3}>
                            <div className="position-relative">
                              <label>User</label>
                              <input
                                onChange={e => handleFilterChange(e, "user")}
                                id="search-bar-0"
                                type="text"
                                className="form-control rounded custom-input-height"
                                placeholder={`Search by User Name, Email or Phone `}
                                value={userFilter || ""}
                              />
                            </div>
                          </Col> */}
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Project Name</label>
                              <ReactSelect
                                users={investmentList}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "projectType")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["project_name"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Status</label>
                              <ReactSelect
                                users={dematTransferRequestsStatus}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "status")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["statusText"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-4">
                              <Label>Transfer Date</Label>
                              <Input
                                name="request_date"
                                type="date"
                                value={requestDateFilter}
                                onChange={e => {
                                  handleFilterChange(e, "requestDate")
                                }}
                                className="custom-input-height"
                              />
                            </div>
                          </Col>
                        </Row>
                        {/* Table and Modal start */}
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              columns={TableColumns()}
                              data={tableData}
                              selectRow={selectRow}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal
                              isOpen={createRequestModal}
                              toggle={toggleCreateRequestModal}
                            >
                              <ModalHeader
                                toggle={toggleCreateRequestModal}
                                tag="h4"
                              >
                                Generate Demat Transfer Request
                              </ModalHeader>
                              <ModalBody>
                                <AvForm
                                  onValidSubmit={createDematTransferRequest}
                                >
                                  <div className="row" form>
                                    <div className="mb-3">
                                      <div className="d-flex justify-content-between">
                                        <div className="col-auto">
                                          <label htmlFor="stf_documents">
                                            Select STF Documents
                                          </label>
                                        </div>
                                        {accessRestriction === "SuperAdmin" ? (
                                          <div className="col-auto d-flex gap-2 justify-content-end align-items-center">
                                            <input
                                              type="checkbox"
                                              id="selectAll"
                                              checked={selectAll}
                                              onChange={e =>
                                                setSelectAll(e.target.checked)
                                              }
                                            />{" "}
                                            <label
                                              className="mb-0 text-muted"
                                              htmlFor="selectAll"
                                            >
                                              Select All
                                            </label>
                                          </div>
                                        ) : null}
                                      </div>
                                      {!selectAll ? (
                                        <ReactMultiSelect
                                          id="stf_documents"
                                          options={formattedStfDocuments}
                                          setSelectedOption={
                                            setSelectedMultiStfDocument
                                          }
                                          selectedOption={
                                            selectedMultiStfDocument
                                          }
                                          multiOptionLabel={true}
                                          optionLabelKeys={["label"]}
                                        />
                                      ) : (
                                        <p className="text-secondary">
                                          Selected:{" "}
                                          {formattedStfDocuments?.length} STF
                                          Documents
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="mb-4">
                                    <Label>Transfer Date</Label>
                                    <Input
                                      name="date"
                                      type="date"
                                      value={date}
                                      onChange={e => setDate(e.target.value)}
                                      className="custom-input-height"
                                      min={moment().format("YYYY-MM-DD")}
                                    />
                                  </div>
                                  <Row className="mb-2 mt-4 justify-content-end">
                                    {/* <div className="col-auto d-flex gap-2 justify-content-end align-items-center">
                                      <input
                                        type="checkbox"
                                        id="consent"
                                        checked={group}
                                        onChange={e =>
                                          setGroup(e.target.checked)
                                        }
                                      />
                                      <label
                                        className="mb-0 text-muted"
                                        htmlFor="consent"
                                      >
                                        Combine matching investor-project
                                        entries
                                      </label>
                                    </div> */}
                                    <div className="col-auto text-end">
                                      <Button
                                        type="submit"
                                        color="success"
                                        className={`btn-md save-user`}
                                        disabled={
                                          (!(
                                            selectAll &&
                                            formattedStfDocuments.length
                                          ) &&
                                            !selectedMultiStfDocument.length) ||
                                          !date
                                        }
                                      >
                                        Create
                                      </Button>
                                    </div>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                            <Modal
                              isOpen={downloadErrorModal}
                              toggle={toggleDownloadErrorModal}
                            >
                              <ModalHeader
                                toggle={toggleDownloadErrorModal}
                                tag="h4"
                              >
                                Invalid Request!
                              </ModalHeader>
                              <ModalBody>
                                <Row form>
                                  Download requested for more than 500 entries.
                                  Please select a filter before proceeding.
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="text-end">
                                      <button
                                        type="button"
                                        className="btn btn-success save-user"
                                        onClick={toggleDownloadErrorModal}
                                      >
                                        OK
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </ModalBody>
                            </Modal>
                            <Modal
                              isOpen={editRequestFailedModal}
                              toggle={toggleEditRequestFailedModal}
                            >
                              <ModalHeader
                                toggle={toggleEditRequestFailedModal}
                                tag="h4"
                              >
                                Update Status
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={updateDTRequestFailed}>
                                  <div className="mb-3">
                                    <p>
                                      <span
                                        className={`badge badge-pill font-size-12 badge-soft-danger`}
                                      >
                                        TRANSFER FAILED
                                      </span>{" "}
                                      status will be changed to{" "}
                                      <span
                                        className={`badge badge-pill font-size-12 badge-soft-secondary`}
                                      >
                                        RE-INITIATED
                                      </span>
                                    </p>
                                  </div>
                                  <div className="mb-3">
                                    <Label>Transfer Date</Label>
                                    <Input
                                      name="date"
                                      type="date"
                                      value={date}
                                      onChange={e => setDate(e.target.value)}
                                      className="custom-input-height"
                                      min={moment().format("YYYY-MM-DD")}
                                    />
                                  </div>
                                  <div className="col-auto text-end">
                                    <Button
                                      type="submit"
                                      color="success"
                                      className={`btn-md save-user`}
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                            <Modal
                              isOpen={editRequestMappingPendingModal}
                              toggle={() =>
                                setEditRequestMappingPendingModal(
                                  !editRequestMappingPendingModal
                                )
                              }
                            >
                              <ModalHeader
                                toggle={() =>
                                  setEditRequestMappingPendingModal(
                                    !editRequestMappingPendingModal
                                  )
                                }
                                tag="h4"
                              >
                                Update Status
                              </ModalHeader>
                              <ModalBody>
                                <AvForm
                                  onValidSubmit={updateDTRequestMappingPending}
                                >
                                  <div className="mb-3">
                                    <p>
                                      <span
                                        className={`badge badge-pill font-size-12 badge-soft-danger`}
                                      >
                                        MAPPING PENDING
                                      </span>{" "}
                                      status will be changed to{" "}
                                      <span
                                        className={`badge badge-pill font-size-12 badge-soft-danger`}
                                      >
                                        TRANSFER FAILED
                                      </span>
                                    </p>
                                  </div>
                                  <div className="col-auto text-end">
                                    <Button
                                      type="submit"
                                      color="success"
                                      className={`btn-md save-user`}
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                        {/* Table and Modal End */}
                        {/* SSP Pagination Start */}
                        <Row className="justify-content-md-space-between justify-content-center align-items-center">
                          <Col className="col-12 col-md-auto mb-3">
                            {`Showing ${total ? skip + 1 + " to" : ""} ${
                              limit > total || limit + skip > total
                                ? total
                                : limit + skip
                            } rows of ${total}
                 `}
                          </Col>
                          <Col>
                            <Row className="justify-content-md-end justify-content-center align-items-center">
                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(limit)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<<"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(skip)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<"}
                                  </Button>
                                </div>
                              </Col>
                              <Col className="col-md-auto d-none d-md-block">
                                Page{" "}
                                <strong>{`${currentPage ? currentPage : 1} of ${
                                  totalPage ? totalPage : 1
                                }`}</strong>
                              </Col>
                              <Col className="col-md-auto">
                                <Input
                                  type="number"
                                  min={1}
                                  style={{ width: 70 }}
                                  max={total == 0 ? 1 : totalPage}
                                  value={currentPage || 1}
                                  defaultValue={1}
                                  onChange={onChangePagination}
                                  disabled={total == 0}
                                />
                              </Col>

                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handleNext(skip)}
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      handleNext((totalPage - 2) * limit)
                                    }
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">>"}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {/* SSP Pagination End */}
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

DematTransferRequest.propTypes = {
  tableData: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
}

export default withRouter(DematTransferRequest)
